import React, { FC, ChangeEvent } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => ({
    productCoreValues: {
        background: "white",
        width: "100%",
        margin: "0 auto",
        padding: 16,
        color: "rgba(0, 0, 0, 0.6)",
        "& h2": {
            marginBottom: 32,
            fontSize: 24,
            lineHeight: "28px",
            [theme.breakpoints.down('md')]: {
                marginBottom: 8
            }
        },
        "& ul": {
            listStyleType: "none",
            fontFamily: "Open Sans",
            fontSize: 16,
            lineHeight: "24px",
            letterSpacing: "0.5px",
            fontWeight: 400,
            margin: 0,
            padding: 0,
            maxWidth: 585
        },
        "& li": {
            marginBottom: 24,
            [theme.breakpoints.down('md')]: {
                marginBottom: 8
            }
        }
    },
    tabsWrapper: {
        maxWidth: 1080,
        margin: "0 auto"
    },
    mobileSubheading: {
        fontSize: 20,
        lineHeight: "24px",
        letterSpacing: "0.25px"
    },
    tabPanelInner: {
        display: "flex",
        alignItems: "flex-start",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column"
        }
    },
    tabPanelImage: {
        flexShrink: 0,
        boxShadow: "0px 24px 32px rgba(0, 0, 0, 0.08), 0px 12.5px 128px rgba(0, 0, 0, 0.08)",
        borderRadius: 4,
        marginRight: 45,
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
            width: "100%",
            height: "auto",
            marginBottom: 8
        }
    }
}))

interface IPropsTabPanel {
    value: number
    index: number
    isMobile?: boolean
}

const TabPanel: FC<IPropsTabPanel> = ({ children, value, index, isMobile }) => {
    const classes = useStyles()
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            style={{
                background: "white",
                padding: isMobile ? 16 : "16px 25px 24px 25px",
                fontWeight: 500,
                letterSpacing: "0.15px"
            }}
        >
            <div className={classes.tabPanelInner}>{value === index && children}</div>
        </div>
    )
}

const tabStyleOverrides = {
    color: "rgba(0,0,0,0.64)",
    fontFamily: "Ubuntu",
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: "1px",
    marginBottom: 6,
    marginTop: 1
}

interface IPropsProductCore {
    isMobile?: boolean
}

const ProductCoreValuesSection: FC<IPropsProductCore> = ({ isMobile }) => {
    const classes = useStyles()

    const [tabValue, setTabValue] = React.useState(0)
    const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue)
    }

    const data = useStaticQuery(graphql`
        query {
            circleOfNuliaAssessmentProcessImage: file(relativePath: { eq: "product/circle-of-nulia-assessment-process.png" }) {
                childImageSharp {
                    fixed(width: 382) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(maxWidth: 959) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            circleOfNuliaBuildProcessImage: file(relativePath: { eq: "product/circle-of-nulia-build-process.jpg" }) {
                childImageSharp {
                    fixed(width: 382) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(maxWidth: 959) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            circleOfNuliaChampionService: file(relativePath: { eq: "product/circle-of-nulia-champion-service.jpeg" }) {
                childImageSharp {
                    fixed(width: 382) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(maxWidth: 959) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <section className={classes.productCoreValues}>
            <div className={classes.tabsWrapper}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons={isMobile ? "on" : "off"}
                    aria-label="The three core values of Nulia Works"
                    style={{
                        background: "white",
                        borderRadius: "4px",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.08)"
                    }}
                    TabIndicatorProps={{
                        children: (
                            <span
                                style={{
                                    height: 4,
                                    maxWidth: 138,
                                    width: "100%",
                                    backgroundColor: "#654EA3",
                                    borderRadius: 4
                                }}
                            />
                        ),
                        style: {
                            height: 4,
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "transparent"
                        }
                    }}
                >
                    {/* TODO: A11y Props for tabs */}
                    <Tab label="ASSESS PROCESS" style={tabStyleOverrides} />
                    <Tab label="BUILD PROCESS" style={tabStyleOverrides} />
                    <Tab label="CHAMPION PROCESS" style={tabStyleOverrides} />
                </Tabs>
                <TabPanel value={tabValue} index={0} isMobile={isMobile}>
                    {!isMobile && (
                        <Img
                            fixed={data.circleOfNuliaAssessmentProcessImage.childImageSharp.fixed}
                            alt="A screenshot of the Insights page on the Nulia Works site"
                            className={classes.tabPanelImage}
                        />
                    )}
                    {isMobile && (
                        <Img
                            fluid={data.circleOfNuliaAssessmentProcessImage.childImageSharp.fluid}
                            alt="A screenshot of the Insights page on the Nulia Works site"
                            className={classes.tabPanelImage}
                        />
                    )}
                    <div>
                        <h2>Circle of Nulia Methodology: Assess Process</h2>
                        <ul>
                            <p>
                                Our Assess Process leverages our Nulia Works Measure & Evaluate Pillar to provide 
                                an in-depth evaluation of an organization’s technology adoption, comparing it to 
                                established adoption goals and business value drivers. 
                                The Assess Process is based on an organization’s actual Microsoft 365 usage 
                                and identifies skills that have been developed and are being used, and uncovers new 
                                skill development opportunities and Outcome attainment goals.
                            </p>
                        </ul>
                    </div>
                </TabPanel>
                <TabPanel value={tabValue} index={1} isMobile={isMobile}>
                    {!isMobile && (
                        <Img
                            fixed={data.circleOfNuliaBuildProcessImage.childImageSharp.fixed}
                            alt="A screenshot of the Outcomes and other tools on on the Nulia Works site"
                            className={classes.tabPanelImage}
                        />
                    )}
                    {isMobile && (
                        <Img
                            fluid={data.circleOfNuliaBuildProcessImage.childImageSharp.fluid}
                            alt="A screenshot of the Outcomes and other tools on on the Nulia Works site"
                            className={classes.tabPanelImage}
                        />
                    )}
                    <div>
                        <h2>Circle of Nulia Methodology: Build Process</h2>
                        <ul>
                           <p>
                                Our Build Process leverages our Nulia Works Personalize & Guide Pillar to personalize 
                                a specific Microsoft 365 adoption & change management plan for each organization and employee. 
                                Through our Solution Discovery and Design process, Outcomes are selected to drive achievement of an 
                                organization’s prioritized business value drivers. And new Outcomes can be quickly added to the catalog 
                                to address specific customer user cases, vertical industry solutions, and role specific adoption needs.
                           </p>
                        </ul>
                    </div>
                </TabPanel>
                <TabPanel value={tabValue} index={2} isMobile={isMobile}>
                    {!isMobile && (
                        <Img
                            fixed={data.circleOfNuliaChampionService.childImageSharp.fixed}
                            alt="A picture of people working together happily"
                            className={classes.tabPanelImage}
                        />
                    )}
                    {isMobile && (
                        <Img
                            fluid={data.circleOfNuliaChampionService.childImageSharp.fluid}
                            alt="A picture of people working together happily"
                            className={classes.tabPanelImage}
                        />
                    )}
                    <div>
                        <h2>Circle of Nulia Methodology: Champion Service</h2>
                        <ul>
                            <p>
                                Our Champion Process leverages our Nulia Works Engage & Reward Pillar to continuously engage 
                                employees to drive their digital skills development, getting them to deeply adopt and use Microsoft 365 apps, 
                                features, and functions. Champions make the change process fun by providing reward programs utilizing Nulia Works 
                                features like engagement points and achievements. All leading to employees developing skills and attaining badges 
                                which certify outcome attainment.
                            </p>
                        </ul>
                    </div>
                </TabPanel>
            </div>
        </section>
    )
}

export default ProductCoreValuesSection
